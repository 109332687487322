:root {
    --primary: #F33B30;
    --secondary: #FDAC06;
    --alternate1: #9EE59A;
    --alternate2: #2B3F47;
    --highlight: #FFFFFF;
    --klaxonRed: #F33B30;
    --inputGrey: #404040;
    --cardGrey: #222222;
}


.lightmodeNavLink {
    color: black !important;
    font-size: 12px !important;
    font-weight: bold
}

.lightmodeLogin {
    color: #FFF;
    background-color: #000;
    border: none;
    min-width: 150px;
    min-height: 50px;
    border-radius: 30px;
    font-weight: bold
}

.altBackground {
    background: linear-gradient(rgba(74, 144, 226, 0.1), rgba(243, 59, 48, 0.1));
}

.scrollingItems {
    display: flex;
    gap: 25px;
    overflow: auto;
    width: 100%;
    min-height: 300px;
    justify-content: center
}

.scrollingItemsScroll {
    display: flex;
    gap: 25px;
    overflow: auto;
    width: 100%;
    min-height: 300px;
    /*    --mask: linear-gradient(to right, rgba(0,0,0, 1) 0, rgba(0,0,0, 1) 93%, rgba(0,0,0, 0) 99%, rgba(0,0,0, 0) 0 ) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);*/
}

.scrollingItemsDrop {
    display: flex;
    gap: 25px;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    flex-wrap: wrap;
}

    .scrollingItemsDrop > div {
        border: 3px solid rgba(74, 144, 226, 0.7);
        border-radius: 10px;
        max-height: 250px;
        max-width: 23%;
        min-width: 10% !important;
        /*    flex-basis: 24%;*/
        box-shadow: 6px 4px 3px rgba(74, 144, 226, 0.5)
    }

#swal2-title {
    margin-top: 10px !important
}

.scrollingItemsScroll::-webkit-scrollbar {
    display: none
}

.scrollingItems::-webkit-scrollbar {
    display: none
}

.lightmodeCard {
    border: 3px solid rgba(74, 144, 226, 0.7);
    border-radius: 10px;
    max-height: 250px;
    /*    max-width: 25%;*/
    min-width: 23%;
    max-width: 23%;
    /*    flex-basis: 24%;*/
    box-shadow: 6px 4px 3px rgba(74, 144, 226, 0.5)
}

.lightmodeCardScroll {
    border: 3px solid rgba(74, 144, 226, 0.7);
    border-radius: 10px;
    max-height: 250px;
    /*    max-width: 25%;*/
    min-width: 23%;
    /*    flex-basis: 24%;*/
    box-shadow: 6px 4px 3px rgba(74, 144, 226, 0.5)
}

.lightmodeCardAlt {
    border: 3px solid rgba(243, 59, 48, 0.7);
    border-radius: 10px;
    max-height: 250px;
    /*    max-width: 25%;*/
    min-width: 23%;
    /*    flex-basis: 24%;*/
    box-shadow: 6px 4px 3px rgba(243, 59, 48, 0.5)
}

.lightmodeCardImage {
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 8px
}

.lightmodeCard2 {
    border: 1px solid rgba(14, 14, 14, 0.1);
    border-radius: 10px;
    max-height: 250px;
    /*    max-width: 25%;*/
    min-width: 100%;
    max-width: 100%;
    /*    flex-basis: 24%;*/
    box-shadow: 1px 2px 5px rgba(16, 24, 40, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding: 15px 0
}

.lightmodeCardImage2 {
    width: 90%;
    min-height: 150px !important;
    object-fit: cover;
    border-radius: 8px
}

.scrollButton {
    padding: 13px 0 13px 0;
    min-width: 40px;
    margin: 0 5px 0 5px;
    text-align: center;
    border-radius: 30px
}

.scrollButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subText {
    color: var(--klaxonRed)
}

.lightmodeTopic {
    border: none;
    min-width: 150px;
    min-height: 50px;
    border-radius: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--klaxonRed)
}

    .lightmodeTopic:hover {
        background-color: #F5F5F5
    }

.topicItemActiveLight {
    color: white;
    background-color: black !important;
    border: none;
}


.navSearch {
    border: none;
    width: 90%;
    background-color: transparent !important;
}

    .navSearch:focus-visible {
        background-color: #F8F8F8;
        outline: none
    }


.scrollingItemsPodcast {
    display: flex;
    flex-wrap: wrap;
}

.lightmodeCardPodcast {
    flex: 30%;
}

.css-yk16xz-control:hover {
    border-color: transparent !important
}

.css-1pahdxg-control {
    box-shadow: none !important;
    border-color: transparent !important
}

.rtbGrad {
    background: linear-gradient(to bottom, #F33B30 0%, #000000 95%);
    color: #fff;
}

.rtbGrad2 {
    background: linear-gradient(to bottom, #F33B30 0%, #000000 85%);
    color: #fff;
}

.whiteButton {
    background-color: #fff;
    max-width: 150px;
    min-width: 150px;
    max-height: 45px;
    min-height: 45px;
    font-weight: bold;
    border: none;
}

.clientPageButton {
    color: var(--primary);
    border-radius: 10px;
}

.gradient-text {
    background: linear-gradient(to bottom, #F33B30 0%, #000000 95%);
    -webkit-background-clip: text; /* Required for Chrome, Safari, and newer versions of Edge */
    background-clip: text; /* Works in Firefox */
    -webkit-text-fill-color: transparent; /* Required for Chrome, Safari, and newer versions of Edge */
    color: transparent; /* Needed for Firefox */
}

.editDropdown {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
}

    .editDropdown:hover {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: rgba(0, 0, 0, 0.2) !important;
    }

    .editDropdown:active {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: rgba(0, 0, 0, 0.2) !important;
    }

    .editDropdown:focus {
        background-color: rgba(0, 0, 0, 0.05) !important;
        color: rgba(0, 0, 0, 0.2) !important;
    }

        .editDropdown:focus:active {
            background-color: rgba(0, 0, 0, 0.05) !important;
            color: rgba(0, 0, 0, 0.2) !important;
        }

.editDropdownMenu > .dropdown-item {
    background-color: #fff !important;
    color: #000 !important
}

    .editDropdownMenu > .dropdown-item:hover {
        color: rgba(0, 0, 0, 0.2) !important;
    }

.progressBarLight {
    /* Appearance reset for cross-browser consistency */
    -webkit-appearance: none; /* Chrome and Safari */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Default for other browsers */
    width: 100%;
    height: 8px; /* Adjust height as needed */
    background: rgba(250, 250, 250, 1); /* Removes default styling */
    outline: none; /* Removes focus outline */
    border-radius: 5px; /* Optional: Rounded corners */
    overflow: hidden; /* Ensures background is clipped to border-radius */
}

    /* WebKit browsers (Chrome, Safari) */
    .progressBarLight::-webkit-slider-runnable-track {
        background: rgba(250, 250, 250, 0.2);
        width: 100%;
        height: 100%;
    }

    .progressBarLight::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 0;
        background: black;
    }

    /* Firefox */
    .progressBarLight::-moz-range-track {
        background: #fff;
        width: 100%;
        height: 100%;
    }

    .progressBarLight::-moz-range-thumb {
        width: 0;
        height: 0;
        border: none;
        background: #fff;
    }

    /* Internet Explorer */
    .progressBarLight::-ms-track {
        /*  background: transparent;*/
        /*  border-color: transparent;*/
        /*  color: transparent;*/
        width: 100%;
        height: 100%;
    }

    .progressBarLight::-ms-fill-lower {
        /*  background: rgba(250, 250, 250, 1);*/
    }

    .progressBarLight::-ms-fill-upper {
        /*  background: rgba(250, 250, 250, 0.2);*/
    }

    .progressBarLight::-ms-thumb {
        width: 0;
        height: 0;
        border: none;
        background: transparent;
    }

/*audio podcast client mini*/
.volumeControls {
    display: flex;
}

.playbackContainer {
    align-items: flex-end
}

.notOnlySpeed {
}

.onlySpeed {
    display: none;
}

@media (max-width: 600px) {
    .volumeControls {
        display: none;
    }

    .playbackContainer {
        align-items: flex-start
    }
}

.clientPodcastImage {
    height: 75px;
    width: 75px;
}

.clientPodcastContainer {
    align-items: center;
}

.volumeControlsMobile {
    display: none;
}

@media (max-width: 900px) {
    .clientPodcastImage {
        height: 45px;
        width: 45px;
    }

    .clientPodcastContainer {
        flex-direction: column;
        align-items: initial;
        justify-content: center;
    }

    .volumeControlsMobile {
        display: flex
    }
}

.clientPodcastImage {
    height: 75px;
    width: 75px;
}

.clientPodcastContainer {
    align-items: center;
}

.volumeControlsMobile {
    display: none;
}

@media (max-width: 900px) {
    .clientPodcastImage {
        height: 45px;
        width: 45px;
    }

    .clientPodcastContainer {
        flex-direction: column;
        align-items: initial;
        justify-content: center;
    }

    .volumeControlsMobile {
        display: flex
    }

    .volumeControls {
        display: none;
    }

    .playbackContainer {
        display: none !important;
    }

    .onlySpeed {
        display: initial;
    }
}

.clientAudioPlayerContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;
    min-height: 100px;
    max-height: 100px;
    left: 0;
    right: 0;
    transition: bottom 0.5s
}

.clientAudioPlayerHide {
    bottom: -100px;
    transition: bottom 0.5s
}

.tooltip-inner {
    box-shadow: none !important;
}

.moveSortBy {
    justify-content: flex-end
}

@media (max-width:575px) {
    .moveSortBy {
        justify-content: flex-start;
        max-width: 50%
    }

        .moveSortBy > .dropdown {
            /*        justify-content: flex-start;*/
            width: 100%
        }

            .moveSortBy > .dropdown > button {
                /*        justify-content: flex-start;*/
                width: 100%;
            }

    .editDropdownMenu {
        left: 18px !important
    }
}

@media (max-width:380px) {
    .moveSortBy {
        max-width: 100%
    }
}

#root > div > div.ps__rail-x, #root > div > div.ps__rail-y {
    display: none;
}

.titleOpen {
    width: 40%
}

.title2 {
    width: 60%
}

.cardContain {
    width: 60%;
    justify-content: space-between
}

.a13card {
    width: 30%
}

.btnCont {
    width: 60%;
    justify-content: space-between;
}

.btnBtn {
    min-height: 100%;
    justify-content: space-between;
    width: 45%
}

.links1 {
    width: 50%
}

.ytSize {
    width: 100%;
    height: 100%;
}

.ytCont {
    width: 60%;
   height: 500px
}

.changeDir {
    width: 90%;
    justify-content: center;
}

.formWidth {
    width: 25%;
}

@media (max-width: 700px) {
    .formWidth {
        width: 70%
    }

    .changeDir {
        width: 90%;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding-bottom: 25px;
    }

    .changeSide {
        flex-direction: column;
    }

    .cc {
        text-align: center;
    }

    .titleOpen {
        width: 90%
    }

    .cardContain {
        width: 90%
    }

    .title2 {
        width: 100%
    }

    .btnCont {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .btnBtn {
        justify-content: center;
        width: 100%;
        text-align: center;
        max-height: 100px;
    }

    .bp {
        margin-top: 5rem
    }

    .noImg {
        display: none;
    }

    .links1 {
        width: 100%
    }

    .ytCont {
        width: 100%
    }

    .ytSize {
        width: 100%;
        /*        height: auto;*/
    }

    .ePadd {
        margin-bottom: 150px;
    }
}

@media (max-width: 550px) {
    .formWidth {
        width: 90%
    }

    .a13card1 {
        width: 90%
    }

    .a13card {
        width: 90%
    }

    .cardContain {
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
    }
}

.clientNav {
    width: 90%;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: space-between
}

.clientLinks {
    justify-content: space-between
}

@media (max-width: 1050px) {
    .clientLinks {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 800px) {
    .clientContain {
        flex-direction: column;
        align-items: center;
    }

    .clientLinks {
        align-items: center;
    }
}

.hiddenStart {
    display: none;
}

.clientIconLinks {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 420px) {
    .unhide {
        display: none;
    }

    .hiddenStart {
        display: initial
    }

    .clientIconLinks {
        justify-content: center;
    }
}

@media (max-width: 500px) {
    .clientNav {
        flex-direction: column;
        gap: 10px;
    }
}

.buttonChanger:focus {
    background-color: darkorange !important;
    filter: brightness(0.8);
}

.buttonChanger:active {
    background-color: darkorange !important;
    filter: brightness(0.8);
}

.buttonChanger:hover {
    background-color: darkorange !important;
    filter:brightness(0.8);
}

@media (max-width: 600px) {
    .featuredCont {
        flex-direction: column;
        align-items: center;
        gap: 25px;
    }

    .featuredItem {
        display: flex;
        justify-content: center;
    }

    .featuredItem > img{
        width: 100%
    }
}

.definedTitle {
    font-size: 2rem;
}

.waitingListTop {
    width: 60%
}

@media (max-width: 900px) {
    .waitingListTop {
        width: 80%
    }
}

@media (max-width: 600px) {
    .waitingListTop {
        width: 100%
    }
}

.softerBorder {
    border: 1px solid rgba(0, 0, 0, 0.2) !important
}

.backgroundOutside > .form-control:focus {
    background-color: rgba(220, 220, 220, 0.5) !important;
    color: rgba(0, 0, 0, 0.8) !important
}

.stripeMove {
    margin: 0
}

.klaxonColorUpload {
    border: 2px dashed var(--klaxonRed) !important
}

.klaxonColorUpload > svg > path {
    fill: var(--klaxonRed) !important
}

.react-select .react-select__menu {
    right: 0;
    width: 100%;
    max-width: 100%;
}

.playerBar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: linear-gradient(to right, #F33B30, #F33B30);
    border-radius: 5px;
    outline: none;
    height: 8px;
}

    .playerBar::-webkit-slider-runnable-track {
        background: linear-gradient(to right, #F33B30, #F33B30);
        height: 8px;
        border-radius: 5px;
    }

    .playerBar::-moz-range-track {
        background: linear-gradient(to right, #F33B30, #F33B30);
        height: 8px;
        border-radius: 5px;
    }

    .playerBar::-webkit-slider-thumb {
/*        -webkit-appearance: none;
        appearance: none;*/
        width: 16px; /* Adjust size */
        height: 16px; /* Adjust size */
        background: initial;
        border-radius: 50%;
        margin-top: -3.5px; /* Align the thumb vertically with the track */
        cursor: pointer;
    }

.menuTopColor {
    background-color: rgb(64, 64, 64);
}