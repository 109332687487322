.makeHover:hover {
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer
}

.makeHover {
    margin-bottom: 10px;
}

/*Podcast Player Page*/
.podcastPlayer {
    /*    background-color: red;*/
    display: flex;
    align-content: center;
}

.playerContainer {
    display: flex;
    align-items: center;
}

.expandable-test {
    border-top: none !important;
}


.tableLast {
    border-bottom: none !important;
}

.contact-form {
    /*    background-color: red;*/
    width: 50%;
    justify-content: center;
/*    border: 1px solid grey;*/
    border-radius: 5px;
    /*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);*/
    padding: 10px;
}

.contact-form {
    width: 70%;
}

    .contact-form > form {
        width: 90%;
    }

        .contact-form > form > div {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }

            .contact-form > form > div > input {
                padding-left: 5px;
                min-height: 50px;
                font-size: 16px;
            }

            .contact-form > form > div > textarea {
                padding-left: 5px;
                min-height: 50px;
                font-size: 16px;
            }

            .contact-form > form > div > label {
                text-align: left;
                font-weight: 600;
                color: red;
            }

.submitButton {
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    background-color: red;
    width: 40%;
    min-height: 40px;
}

/*Stripe Stuff*/
.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #404040;
    will-change: opacity, transform;
    
    border-radius: 5px;
    width: 100%;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 15px;
}

.StripeElement--webkit-autofill {
    background: #404040 !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.payButton {
    border: 2px solid red;
    border-radius: 5px;
    width: 50%;
    padding: 10px 0 10px 0;
    font-weight: bold;
    color: white;
    transition: box-shadow 0.3s ease;
    background-color: red;
    text-transform: uppercase
}

.payButton:hover {
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.3);
}

/*Fake hover form auth*/
.fakeHoverEffect {
    border: 1px solid darkgrey;
    border-radius: 5px
}

.inputRemoveHover:focus {
    outline: 0 !important;
    box-shadow: none !important;
    color: #FFF !important;
    border: none !important;
}

.sidebar::after {
    background: transparent !important;
}

/*i don't think this works for all browsers*/
.reviewInput::-webkit-scrollbar {
    width: 8px;

}

.reviewInput::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 2px;
    transition: background-color 1s ease;
}

    .reviewInput::-webkit-scrollbar-thumb:hover {
        background-color: darkgrey;
    }

.reviewInput::-webkit-scrollbar-track {
    background-color: lightgrey;
    transition: background-color 1s ease;
}

.shimmer {
    border-radius: 25px
}

.moving-tab {
    font-weight: bold !important; /* Make the text bold */
}

