@import 'variables';

.sidebar-container {
    background-color: $background-color-dark;

    body.light-mode & {
        background-color: #fff;
        border-right-color: $background-color-dark;
    }
}

.sidebar-wrapper > div > ul > li > a {
    color: $text-color-dark;

    body.light-mode & {
        color: $text-color-light
    }
}

.sidebar .nav i, .off-canvas-sidebar .nav i {
    color: $text-color-dark;

    body.light-mode & {
        color: $text-color-light
    }
}

.sidebar[data-active-color=primary] .nav li.active > a, .sidebar[data-active-color=primary] .nav li.active > a i, .sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse], .sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse] i, .sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active .sidebar-mini-icon, .sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active > a, .off-canvas-sidebar[data-active-color=primary] .nav li.active > a, .off-canvas-sidebar[data-active-color=primary] .nav li.active > a i, .off-canvas-sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse], .off-canvas-sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse] i, .off-canvas-sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active .sidebar-mini-icon, .off-canvas-sidebar[data-active-color=primary] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active > a {
    color: $text-color-dark;

    body.light-mode & {
        color: $text-color-light
    }
}

.sidebar .sidebar-wrapper li.active > a:not([data-toggle=collapse]):before {
    color: $text-color-dark;

    body.light-mode & {
        border-right-color: $text-color-light;
    }
}

.navExtraSection {
    div {
        div {
            background-color: $card-background-color-dark;

            body.light-mode & {
                background-color: $card-background-color-light;
            }

            p {
                color: $text-color-dark;

                body.light-mode & {
                    color: $text-color-light;
                }
            }
        }

        u, p {
            color: $text-color-dark;

            body.light-mode & {
                color: $text-color-light;
            }
        }
    }
}

.main-panel > .navbar {
    background-color: $background-color-dark !important;

    body.light-mode & {
        background-color: #fff !important;
    }
}

.navbar .navbar-wrapper {
    h4, u {
        color: $text-color-dark !important;

        body.light-mode & {
            color: $text-color-light !important
        }
    }
}

.react-select {
    background-color: $input-background-dark;
    color: $text-color-dark !important;

    body.light-mode & {
        background-color: $input-background-light;
        color: $text-color-light !important
    }
}

.react-select .react-select__control .react-select__value-container--has-value .react-select__single-value {
    color: $text-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important
    }
}

.react-select__placeholder {
    color: $text-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important
    }
}

.navbar.navbar-transparent .nav-item .nav-link:not(.btn) {
    color: $text-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important
    }
}

.react-select__menu, .react-select__option {
    background-color: $input-background-dark !important;
    color: $text-color-dark !important;

    body.light-mode & {
        background-color: $input-background-light !important;
        color: $text-color-light !important;
    }
}

.react-select__menu::before {
    display: none !important;
}

.react-select__menu::after {
    display: none !important;
}


.dropdown-item {
    color: $text-color-dark !important;
    background-color: $input-background-dark !important;

    body.light-mode & {
        color: $text-color-light !important;
        background-color: $input-background-light !important;
    }
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
    color: $text-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important;
    }
}

.formStyle {
    color: $text-color-dark !important;
    background-color: $card-background-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important;
        background-color: $card-background-color-light !important;
    }

    .card-title {
        color: $text-color-dark !important;

        body.light-mode & {
            color: $text-color-light !important;
        }
    }
}

.backgroundMain2 {
    background-color: $background-color-dark;

    body.light-mode & {
        background-color: $background-color-light
    }
}

.inputStyle {
    background-color: $input-background-dark;

    body.light-mode & {
        background-color: $input-background-light;
        color: $text-color-light
    }
}

.inputStyle::placeholder {
    //background-color: $input-background-dark;
    color: rgba(250, 250, 250, 0.5);

    body.light-mode & {
        background-color: $input-background-light;
        color: rgba(0, 0, 0, 0.6)
    }
}

.inputStyle:active {
    background-color: $input-background-dark;

    body.light-mode & {
        background-color: $input-background-light !important;
        color: $text-color-light !important
    }
}

.inputStyle:focus {
    background-color: $input-background-dark;

    body.light-mode & {
        background-color: $input-background-light !important;
        color: $text-color-light !important;
    }
}

.table {
    color: $text-color-dark;

    body.light-mode & {
        color: $text-color-light
    }
}

.modal-content {
    background-color: $background-color-dark;

    body.light-mode & {
        background-color: $background-color-light;
        color: $text-color-light
    }
}

.react-select__multi-value {
    background-color: $card-background-color-dark !important;

    body.light-mode & {
        background-color: $card-background-color-light !important;
    }
}

.react-select__multi-value > div {
    color: $text-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important
    }
}

.tutModalText {
    color: $text-color-dark;

    body.light-mode & {
        color: $text-color-light
    }
}

.whiteLabel {
    color: #fff !important;

    body.light-mode & {
        color: $text-color-light
    }
}

.labelText {
    color: #fff;

    body.light-mode & {
        color: $text-color-light
    }
}

.labelTextLink {
    color: #fff !important;

    body.light-mode & {
        color: $text-color-light !important
    }
}


.card-wizard {
    color: $text-color-dark !important;
    background-color: $card-background-color-dark !important;

    body.light-mode & {
        color: $text-color-light !important;
        background-color: $card-background-color-light !important;
    }
}

.card-wizard .nav-pills > li > a {
    body.light-mode & {
        color: $text-color-light !important
    }
}

.findMark::marker {
    color: transparent !important;
}

.rdt > input {
    background-color: $input-background-dark;

    body.light-mode & {
        background-color: $input-background-light !important;
        color: $text-color-light !important;
    }
}

.rdt {
    table {
        tbody {
            color: black
        }
    }
}

.fadedKlaxonRed {
    background-color: $faded-klaxon-red;
    border-radius: 5px;
    padding: 15px;
    height: 50px;
}

.borderLight {
    border: $faint-border-dark;

    body.light-mode & {
        border: $faint-border-light;
    }
}

.klaxonRed {
    color: $klaxon-red
}

.smallAltButton {
    background-color: $small-alt-button-dark;
    color: #fff;
    border: 1px solid transparent;

    body.light-mode & {
        background-color: $input-background-light;
        color: #000;
        border: $faint-border-light;
    }
}

#exampleSelect {
    background-color: $input-background-dark;
    color: #fff;

    body.light-mode & {
        background-color: $input-background-light;
        color: #000;
    }
}

#exampleSelect::-webkit-scrollbar {
    width: 8px;
}

#exampleSelect::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 2px;
    transition: background-color 1s ease;
}

#exampleSelect::-webkit-scrollbar-thumb:hover {
    background-color: darkgrey;
}

#exampleSelect::-webkit-scrollbar-track {
    background-color: lightgrey;
    transition: background-color 1s ease;
}

.stripeCustom {
    background-color: $input-background-dark;

    .InputElement {
        color: red !important;
    }

    body.light-mode & {
        background-color: $input-background-light;
    }
}

#wave-timeline > div > div > div {
    color: #fff;

    body.light-mode & {
        color: #000;
    }
}

.editButton {
    color: #fff;

    body.light-mode & {
        color: #000;
    }
}

.menuTopColor {
    background-color: rgb(64, 64, 64) !important;

    body.light-mode & {
        background-color: rgba(225, 225, 225, 0.3) !important;
    }
}

.inputAlt {
    background-color: #222222;

    input {
        background-color: #222222;
    }

    body.light-mode & {
        background-color: $input-background-light;

        input {
            background-color: $input-background-light;
        }
    }
}

.biggerInput {
    input {
        min-height: 40px;
    }
}

.sweet-alert {
    background-color: #222222 !important;
    color: white;
    font-family: 'Mark Pro', sans-serif;

    body.light-mode & {
        background-color: #fff !important;
        color: #000;
    }
}

.sweet-alert > div:first-child {
    border-color: #fff !important;

    body.light-mode & {
        background-color: #000 !important;
    }
}


.sweet-alert > div:first-child > span {
    background-color: #fff !important;

    body.light-mode & {
        background-color: #000 !important;
    }
}

.adContentBox {
    background-color: #404040;
    color: #fff;

    body.light-mode & {
        background-color: transparent;
        color: #000;
    }
}
