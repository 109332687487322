$background-color-dark: #1A1A1A;
$text-color-dark: #FFFFFF;
$card-background-color-dark: #222222;
$input-background-dark: #404040;

$background-color-light: #FFFFFF;
$text-color-light: #1A1A1A;
//$card-background-color-light: #e0e0e0;
$card-background-color-light: #fafafa ;
$input-background-light: #f0f0f0;

$background-color-light-off: #fafafa;
$faded-klaxon-red: rgba(243,59,48,0.2);

$faint-border-dark: 1px solid rgba(225, 225, 225, 0.3);
$faint-border-light: 1px solid rgba(0, 0, 0, 0.1);
$klaxon-red: #f33b30;

$small-alt-button-dark: rgb(64, 64, 64);